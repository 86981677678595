import Image from 'next/image'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
} from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { styled, keyframes } from 'stitches.config'

export const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

export const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

const StyledAccordionItem = styled(AccordionPrimitive.Item, {
  backgroundColor: '$darkGray400',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '20px',
})

type AccordionItemProps = {
  children: ReactElement
  isFirst?: boolean
  isLast?: boolean
}

const AccordionItem = ({
  children,
  isFirst = false,
  isLast = false,
  value,
}: AccordionItemProps & AccordionPrimitive.AccordionItemProps) => {
  return (
    <StyledAccordionItem
      css={{
        borderTopLeftRadius: isFirst ? 8 : 0,
        borderTopRightRadius: isFirst ? 8 : 0,
        borderBottomLeftRadius: isLast ? 8 : 0,
        borderBottomRightRadius: isLast ? 8 : 0,
      }}
      value={value}
    >
      {children}
    </StyledAccordionItem>
  )
}

const StyledAccordionTrigger = styled(AccordionPrimitive.AccordionTrigger, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  width: '100%',
  textAlign: 'left',
  height: 52,
  py: 8,
  px: 16,
  fontSize: 14,
  fontWeight: 600,
  img: {
    transition: 'transform 300ms',
  },
  '&[data-state="open"]': {
    borderBottom: '1px solid $neutralBgContent',
  },
  '&[data-state="open"] > img': {
    transform: 'rotate(180deg)',
  },
})

const AccordionTrigger = ({ children }: any) => {
  return (
    <StyledAccordionTrigger>
      {children}{' '}
      <Image
        src="/icons/angle-down.svg"
        alt="accordion arrow"
        width={16}
        height={16}
        aria-hidden
      />
    </StyledAccordionTrigger>
  )
}

const AccordionContent = styled(AccordionPrimitive.AccordionContent, {
  overflow: 'hidden',
  backgroundColor: '$darkGray500',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  py: 8,
  px: 16,
  animation: 'none',
  '&[data-state="open"]': {
    animation: `${slideDown} 0.2ms ease-in`,
    willChange: 'height',
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 0.2ms ease-out`,
  },
})

const AccordionRoot = styled(AccordionPrimitive.Root, {
  borderRadius: 8,
  border: '1px solid $neutralBgContent',
})

const Accordion = forwardRef<
  ElementRef<typeof AccordionPrimitive.Root>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ children }) => {
  return <AccordionRoot type="multiple">{children}</AccordionRoot>
})

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger }
