import Link from 'next/link'
import { styled } from '@stitches/react'
import Spline from '@splinetool/react-spline'

import { Button, Flex, Text } from 'components/primitives'

const BannerImg = styled('div', {
  width: '100%',
  height: '725px',
  backgroundImage: 'url(/bg/homepage-banner-bg.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
})

const Banner = () => {
  return (
    <BannerImg>
      <Flex
        align="center"
        justify="center"
        css={{
          width: '100%',
          height: '100%',
        }}
      >
        <Flex
          justify="center"
          align="center"
          css={{
            width: '100%',
            height: '100%',
            flexShrink: 0,
            textAlign: 'start',
            '@bpMax1280': {
              textAlign: 'center',
              flexDirection: 'column',
              pt: 80,
            },
            '@bpMax960': {
              pt: 120,
            },
          }}
        >
          <Flex
            direction="column"
            css={{
              width: '640px',
              gap: 13,
              '@bpMax1280': {
                width: '300px',
                textAlign: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '@bpMax540': {
                maxWidth: '400px',
              },
            }}
          >
            <Text
              css={{
                fontSize: '40px',
                fontWeight: 700,
                lineHeight: '45px',
              }}
            >
              <Text
                css={{
                  fontSize: '40px',
                  fontWeight: 700,
                  lineHeight: '45px',
                  backgroundImage:
                    'linear-gradient(205deg, #8F00FF -80%, #78A5EA 95.75%, #00FFC2 20%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Buy and sell
              </Text>{' '}
              your items with ease
            </Text>
            <Text>
              Browse all our game collections in one place and checkout using a
              credit card or crypto
            </Text>
            <Flex
              css={{
                gap: 16,
                '@bpMax1280': {
                  textAlign: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  maxWidth: '200px',
                },
              }}
            >
              <Link href="#collections">
                <Button
                  color="defaultPrimary"
                  size="medium"
                  css={{ width: '100%' }}
                >
                  View Collections
                </Button>
              </Link>
              <Link href="#learn-more">
                <Button
                  color="neutralTranslucent"
                  size="medium"
                  css={{ '@bpMax1280': { px: '55px' } }}
                >
                  <p>Learn More</p>
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Flex
            css={{
              width: '40%',
              height: '120%',
              paddingBottom: '120px',
              display: 'block',
              '@bpMax1280': {
                display: 'none',
              },
            }}
          >
            <Spline scene="https://prod.spline.design/K68mEmS4GKVFwx4J/scene.splinecode" />
          </Flex>
        </Flex>
      </Flex>
    </BannerImg>
  )
}

export default Banner
