import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import Divider from 'components/custom/Divider'
import NavLinks from 'components/custom/nav/NavLinks'
import { Anchor, Flex, Text } from '../primitives'

type SectionTitleProps = {
  title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text style="subtitle1" css={{ color: '$gray12', mb: 8 }}>
    {title}
  </Text>
)

type SectionLinkProps = {
  name: string
  href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
  <Anchor
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    weight="medium"
    css={{ fontSize: 14, mt: 16 }}
  >
    {name}
  </Anchor>
)

export const Footer = () => {
  return (
    <Flex
      justify="between"
      direction="column"
      css={{
        gap: '$3',
        '@lg': {
          mx: '$5',
        },
        marginTop: 'auto',
      }}
    >
      <Divider />
      <Flex
        justify="between"
        css={{
          p: '$3',
          '@lg': {
            p: '$5',
          },
          flexDirection: 'row',
          alignItems: 'center',
          //gap: '$3',
          '@bpMax975': {
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
            mt: '$3',
            mb: '$5',
          },
        }}
      >
        <Flex css={{ gap: 80, '@bp600': { gap: 136 } }}>
          <Link href="/">
            <Image
              src="/icons/logoFooter.png"
              alt="Logo"
              width={156}
              height={48}
              priority
              className="m-auto sm:m-0"
            />
          </Link>
        </Flex>
        <Flex
          direction="column"
          css={{
            alignItems: 'flex-start',
            '@bp600': { alignItems: 'flex-end' },
          }}
        >
          <Flex css={{ mt: 16, gap: 16 }}>
            <NavLinks />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}