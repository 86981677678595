import Image from 'next/image'
import Link from 'next/link'

import { Box, Button, Flex, Text } from 'components/primitives'
import Banner from './Banner'
import CollectionGrid from './CollectionGrid'
import FAQ from './FAQ'
import Show from 'components/custom/effects/Show'
import { Footer } from 'components/home/Footer'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SplashPage = ({ collections }: any) => {
  return (
    <>
      <Flex
        direction="column"
        css={{
          h: '100',
          backgroundImage: 'url(/bg/diamond-bg.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}
      >
        <Banner />
        <Flex
          direction="column"
          justify="center"
          align="center"
          css={{
            gap: 42,
            p: '64px 170px 48px',
            '@media (max-width: 975px)': {
              px: 20,
            },
          }}
        >
          {/* CollectionParentGrid */}
          <Flex
            direction="column"
            css={{
              border: '1px solid #273142',
              backgroundColor: '$darkGray400',
              width: '100%',
              maxWidth: '1280px',
              borderRadius: 12,
              padding: 0,
              '@media (m-width: 975px)': {
                border: 'none',
                width: '100vw',
              },
            }}
          >
            {/* Collections Banner */}
            <Box
              css={{
                borderRadius: '12px 12px 0 0',
                overflow: 'hidden',
                '@media (min-width: 975px)': {
                  borderRadius: 0,
                },
              }}
            >
              <Box
                css={{
                  position: 'relative',
                  borderRadius: '12px',
                }}
              >
                <Show
                  css={{
                    display: 'block',
                    '@bpMax975': {
                      borderRadius: '12px 12px 0 0',
                      display: 'none',
                    },
                  }}
                >
                  <Image
                    src={'/images/collection-banner.svg'}
                    alt="homepage collection banner"
                    width="940"
                    height="299"
                    style={{
                      borderRadius: '12px',
                      width: '100%',
                    }}
                  />
                </Show>
                <Show
                  css={{
                    display: 'none',
                    '@bpMax975': {
                      display: 'block',
                    },
                  }}
                >
                  <Image
                    src={'/images/mobile-collection-banner.svg'}
                    alt="mobile homepage collection banner"
                    width="940"
                    height="299"
                    style={{
                      borderRadius: 0,
                      width: '100%',
                    }}
                  />
                </Show>
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  css={{
                    position: 'absolute',
                    top: '5%',
                    right: '63%',
                    bottom: 0,
                    left: 0,
                    gap: 24,
                    '@bpMax975': {
                      top: 0,
                      right: 0,
                    },
                  }}
                >
                  <Image
                    src="/icons/pn-logo.svg"
                    alt="logo"
                    width="295"
                    height="110"
                  />
                  <Link
                    href="https://piratenation.game/play"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size="medium" color="defaultPrimary">
                      Play Now
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        width="16"
                        height="16"
                      />
                    </Button>
                  </Link>
                </Flex>
              </Box>
            </Box>
            <Flex
              direction="column"
              css={{
                px: 24,
                py: 48,
                gap: 24,
              }}
            >
              <Text weight="600" css={{ fontSize: 24 }} id="collections">
                Collections
              </Text>
              <Flex justify="start" align="start" css={{ marginTop: '24px' }}>
                <CollectionGrid collections={collections} />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            css={{
              width: '100%',
              maxWidth: '1280px',
              gap: 28,
              px: 0,
              // '@media (min-width: 975px)': {
              //   px: 24,
              // },
            }}
          >
            <Text style="h4" weight="600" id="learn-more">
              How does this work?
            </Text>
            <FAQ />
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  )
}

export default SplashPage
