import { styled } from '@stitches/react'

const Divider = styled('div', {
  width: '100%',
  height: '1px',
  my: 20,
  mx: 'auto',

  background: '#272832',
})

export default Divider