import Link from 'next/link'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/custom/Accordion'

import { Text } from 'components/primitives'

const content = [
  {
    question: 'What is the Proof of Play marketplace?',
    answer: (
      <Text>
        The Proof of Play marketplace is a peer to peer marketplace where
        players and collectors are able to buy and sell game items. At present,
        the Proof of Play marketplace only hosts game items from our own games.
      </Text>
    ),
  },
  {
    question: 'Who am I purchasing items from?',
    answer: (
      <Text>
        All items listed for sale on the Proof of Play marketplace are being
        sold by individual sellers, who have either previously purchased the
        item themselves, or earned it through gameplay in a Proof of Play built
        game.
      </Text>
    ),
  },
  {
    question: 'How do I list items?',
    answer: (
      <div>
        <Text>Listing items is easy:</Text>
        <br />
        <Text>
          (1) Start by connecting your wallet using the ‘Connect’ button in the
          top right hand corner of the marketplace.
        </Text>
        <br />
        <Text>
          (2) You’ll then see & be able to click the ‘Sell’ button in the same
          position.
        </Text>
        <br />
        <Text>
          (3) Once you’ve clicked the ‘Sell’ button you’ll be taken into a
          dashboard which shows the items you are able to list.{' '}
        </Text>
        <br />
        <Text>
          (4) You can either choose to List your item for sale, or to accept an
          existing offer, via the ‘Instant Sell’ button.'
        </Text>
      </div>
    ),
  },
  {
    question: 'How do Trade Licenses work?',
    answer: (
      <div>
        <Text>
          In order to sell items acquired in Pirate Nation, you need a Trade
          License which can be earned through gameplay, or purchased on the
          Marketplace.{' '}
        </Text>
        <Text>
          <Link
            href="https://docs.piratenation.game/learn/trade-licenses"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ color: '$neutralBg', textDecoration: 'underline' }}>
              Learn More
            </span>
          </Link>
        </Text>
      </div>
    ),
  },
  {
    question: 'How does instant sell work?',
    answer: (
      <Text>
        As it says on the tin! The marketplace allows buyers to make offers on
        game items. If an offer has been made, you as the seller will be able to
        instantly accept the offered amount by clicking on the ‘Instant Sell’
        button.
      </Text>
    ),
  },
  {
    question: 'Can I use a credit card to purchase items?',
    answer: (
      <Text>
        Yes. When you click ‘Buy Now’, a popup will appear asking whether you’d
        like to proceed using crypto or a credit card.
      </Text>
    ),
  },
  {
    question:
      'I have a question that hasn’t been answered here, who should I ask?',
    answer: (
      <div>
        <Text>
          Speak to our team in{' '}
          <Link
            href="https://discord.gg/JwDH7MjT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ color: '$neutralBg', textDecoration: 'underline' }}>
              our Discord
            </span>
          </Link>{' '}
          and we’ll be most happy to help.
        </Text>
      </div>
    ),
  },
]

const FAQ = () => {
  return (
    <Accordion type="multiple">
      {content.map((item, index) => (
        <AccordionItem
          key={index}
          value={`item-${index}`}
          isFirst={index === 0}
          isLast={index === content.length - 1}
        >
          <>
            <AccordionTrigger>
              <Text style="accordionItem">{item.question}</Text>
            </AccordionTrigger>
            <AccordionContent>{item.answer}</AccordionContent>
          </>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default FAQ
