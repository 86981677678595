import { styled } from '@stitches/react'
import Link from 'next/link'
import Image from 'next/image'

import SocialNavLink from './SocialNavLink'
import { Flex, Text } from 'components/primitives'

const SocialButtons = styled('div', {
  border: '1px solid #E8E9F44D',
  width: 52,
  height: 52,
  marginX: '$2',
  p: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    border: '1px solid white',
  },
})

const NavLinks = () => {
  return (
    <>
      <Flex align="center">
        <Link href="/privacy-policy">
          <Text style="subtitle4" css={{ '&:hover': { color: '#CAC6D9' } }}>
            Privacy Policy
          </Text>
        </Link>
      </Flex>
      <Flex align="center">
        <Link href="/terms">
          <Text style="subtitle4" css={{ '&:hover': { color: '#CAC6D9' } }}>
            Terms of Service
          </Text>
        </Link>
      </Flex>
      <SocialNavLink href="https://piratenation.medium.com/">
        <SocialButtons>
          <Image
            src="/icons/socials/medium.png"
            width={19}
            quality={100}
            alt="Medium"
            height={19}
          />
        </SocialButtons>
      </SocialNavLink>
      <SocialNavLink href="https://discord.com/invite/piratenation">
        <SocialButtons>
          <Image
            src="/icons/socials/discord.png"
            width={19}
            quality={100}
            alt="Discord"
            height={19}
          />
        </SocialButtons>
      </SocialNavLink>
      <SocialNavLink href="https://twitter.com/ProofOfPlay/">
        <SocialButtons>
          <Image
            src="/icons/socials/twitter.png"
            width={19}
            quality={100}
            alt="X"
            height={19}
          />
        </SocialButtons>
      </SocialNavLink>
    </>
  )
}

export default NavLinks