import { styled } from '@stitches/react'
import Link from 'next/link'
import { PropsWithChildren } from 'react'

type SocialNavLinkProps = {
  href: string
  className?: string
}

const StyledLink = styled(Link, {})

const SocialNavLink = ({
  children,
  href,
  className,
}: SocialNavLinkProps & PropsWithChildren) => {
  return (
    <StyledLink href={href} passHref>
      <div className={className}>{children}</div>
    </StyledLink>
  )
}

export default SocialNavLink